// import { createMuiTheme } from "@mui/material";
import { createTheme } from '@mui/material/styles'
// import { adaptV4Theme } from '@mui/material/styles';
//import purple from '@mui/material/colors/purple';
// import green from '@mui/material/colors/green';
// import grey from '@mui/material/colors/grey';
// import blueGrey from '@mui/material/colors/blueGrey';
// import deepPurple from '@mui/material/colors/deepPurple';
import {blueGrey, deepPurple, purple} from '@mui/material/colors';
// import lightGreen from '@mui/material/colors/lightGreen';

// import NightmarePillsWoff2 from '../fonts/NIGHTMAREPILLS-Regular.woff2';

// const nightmarepills = {
//   fontFamily: 'NightmarePills',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 400,
//   src: `
//     url(${NightmarePillsWoff2}) format('woff2')
//   `,
// }

let theme = createTheme({
    palette: {
      mode: "dark",
      background: {
        // default: "#121212", // dp00
        default: "#000", // dp00
        paper: "#1e1e1e"    // dp01
      },
      // primary: blueGrey,
      primary: {
        // main: purple['A400'],
        main: deepPurple['200'],
      },
      secondary: deepPurple,
      // highlight: {
      //   main: '#B9FF1F',
      // },
      // highlight: theme.palette.augmentColor({
      //   color: {
      //     main: '#B9FF1F',
      //   },
      //   name: 'highlight',
      // }),
    },
    typography: {
       //fontFamily: 'NightmarePills, Roboto, Helvetica, Arial, sans-serif',
      h1: {
        fontFamily: 'NightmarePills, Roboto, Helvetica, Arial, sans-serif',
      },
      h2: {
        fontFamily: 'NightmarePills, Roboto, Helvetica, Arial, sans-serif',
      },
      h3: {
        fontFamily: 'NightmarePills, Roboto, Helvetica, Arial, sans-serif',
      },
      h4: {
        fontFamily: 'NightmarePills, Roboto, Helvetica, Arial, sans-serif',
      },
      h5: {
        fontFamily: 'NightmarePills, Roboto, Helvetica, Arial, sans-serif',
      }
    },
    // overrides: {
    //   // Style sheet name ⚛️
    //   MuiPaper: {
    //     // Name of the rule
    //     elevation2:  {"background-color": '#222222',}, //dp02
    //     elevation3:  {"background-color": '#242424',}, //dp03
    //     elevation4:  {"background-color": '#272727',}, //dp04
    //     elevation6:  {"background-color": '#2C2C2C',}, //dp06
    //     elevation8:  {"background-color": '#2D2D2D',}, //dp08
    //     elevation12: {"background-color": '#323232',}, //dp12
    //     elevation16: {"background-color": '#353535',}, //dp16
    //     elevation24: {"background-color": '#383838',}, //dp24
    //   },
    //   // MuiCssBaseline: {
    //   //   '@global': {
    //   //     '@font-face': [nightmarepills],
    //   //   },
    //   // },
    // },
    components: {
    //   MuiCssBaseline: {
    //     styleOverrides: `
    //       @font-face {
    //         fontFamily: 'NightmarePills';
    //         fontStyle: 'normal';
    //         fontDisplay: 'swap';
    //         fontWeight: 400;
    //         src: url(${NightmarePillsWoff2}) format('woff2');
    //       }
    //     `
    //   },
      MuiPaper: {
        styleOverrides: {
          // elevation2:  {"background-color": '#222222',}, //dp02
          // elevation3:  {"background-color": '#242424',}, //dp03
          // elevation4:  {"background-color": '#272727',}, //dp04
          // elevation6:  {"background-color": '#2C2C2C',}, //dp06
          // elevation8:  {"background-color": '#2D2D2D',}, //dp08
          // elevation12: {"background-color": '#323232',}, //dp12
          // elevation16: {"background-color": '#353535',}, //dp16
          // elevation24: {"background-color": '#383838',}, //dp24

          elevation2:  {backgroundColor: '#222222',}, //dp02
          elevation3:  {backgroundColor: '#242424',}, //dp03
          elevation4:  {backgroundColor: '#272727',}, //dp04
          elevation6:  {backgroundColor: '#2C2C2C',}, //dp06
          elevation8:  {backgroundColor: '#2D2D2D',}, //dp08
          elevation12: {backgroundColor: '#323232',}, //dp12
          elevation16: {backgroundColor: '#353535',}, //dp16
          elevation24: {backgroundColor: '#383838',}, //dp24
        },
      },
    },
});


theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  palette: {
    highlight: theme.palette.augmentColor({
      color: {
        main: '#B9FF1F',
      },
      name: 'highlight',
    }),
  },
});

export default theme;  